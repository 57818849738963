import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import DelayRender from "../components/DelayRender";
import Search from "../components/Search";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`404 NOT FOUND`}</h1>
    <p>{`We'll deliberate.`}</p>
    <p><a parentName="p" {...{
        "href": "/Welcome"
      }}>{`Knowledge Base`}</a></p>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Home`}</a></p>
    <DelayRender mdxType="DelayRender">
  <Search mdxType="Search" />
    </DelayRender>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      